import React from 'react';
import { Card } from 'flowbite-react';

function BookingConfirmation({ formData, airportData, generalTripData }) {

    let content;
    if (generalTripData.start) {
      content = (
        <>
          <p className="text-gray-600 p-2"><strong>From:</strong> {generalTripData.start} </p>
          <p className="text-gray-600 p-2"><strong>To: </strong>{generalTripData.destination}  </p>

        </>
      );
    } else {
      content = (
        <p className="text-gray-600 p-2"><strong>Airport:</strong> {airportData.airport || 'Not selected'}</p>
      );
    }
    return (
        <div className="bg-gray-50">
            <h2 className="text-3xl font-bold mb-4 text-gray-900 text-center">Booking Confirmation</h2>
            <h3 className="text-lg mb-4 text-gray-600 text-center">Please review your details before confirming your booking.</h3>

            <Card className="border-0 border-gray-200 rounded-none">
                <h3 className="text-xl font-semibold text-gray-900 text-center">Trip type and Price</h3>
                <div className="grid md:grid-cols-2">
                {content}
                    <p className="text-gray-600 px-2"><strong>Car: </strong>{airportData.car || 'No car selected'}</p>
                    <p className="text-gray-600 p-2 text-lg"><strong>Price:</strong> £{airportData.price || generalTripData.price || 'N/A'}</p>
                </div>
            </Card>

            <Card className="border-0 border-gray-200 rounded-none">
                <h3 className="text-xl font-semibold text-gray-900 text-center">Personal Information</h3>
                <div className="grid md:grid-cols-2 gap-2">
                    <p className="text-gray-600"><strong>First Name:</strong> {formData.fname || '...'}</p>
                    <p className="text-gray-600"><strong>Last Name:</strong> {formData.lname || '...'}</p>
                    <p className="text-gray-600"><strong>Email:</strong> {formData.email || '...'}</p>
                    <p className="text-gray-600"><strong>Phone:</strong> {formData.phone || '...'}</p>
                </div>
            </Card>

            <Card className="border-0 border-gray-200 rounded-none">
                <h3 className="text-xl font-semibold text-gray-900 text-center">Travel Details</h3>
                <div className="grid grid-cols-2 gap-2">
                    <p className="text-gray-600"><strong>Departing On:</strong> {formData.ddate || '...'}  at {formData.dtime || '...'}</p>
                        <p className="text-gray-600"><strong>Returning On:</strong> {formData.rdate || 'Not Selected'} at {formData.rtime || 'Not Selected'}</p>
                    <p className="text-gray-600 col-span-2"><strong>Address:</strong> {formData.address || '...'}</p>
                </div>
            </Card>

            <Card className="border-0 border-gray-200 rounded-none">
                <h3 className="text-xl font-semibold text-gray-000 text-center">Additional Notes</h3>
                <p className="text-gray-600">{formData.message || 'No additional notes'}</p>
            </Card>
            <h3 className="text-md font-semibold text-gray-000 text-center p-4">Booking Cancellations and changes</h3>
                <p className="text-gray-600 text-sm">You have the right to cancel your booking up to 24 hours before the booking time. No questions asked just inform us via email or phone. To cancel or change any bookings, you must contact our customer service team. 
                    A booking can be changed in advance by giving up to 24 hours’ notice prior to the transfer time. Changes to booking time and date requested less than 24 hours before the booking time is subject to availability and will incur a £10 admin fee.
                </p>
        </div>
    );
}

export default BookingConfirmation;
